




























































import { Component, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import {
    getStoreProductById,
} from '@/api/consoleApi/stores'

@Component({
    name: 'VsEplusProductListModal',
    components: {
        VsListingCard,
        VsLoader,
    },
})
export default class extends Vue {
    private show = false
    private loading = false
    private storeId: any = ''
    private cartItems: any[] = []
    $refs: any

    public async openModal (storeId: any, cartItems: any[]) {
        this.loading = true
        this.cartItems = []
        this.storeId = storeId
        this.show = true
        await this.getProductByIds(cartItems)
        this.loading = false
    }

    public closeModal () {
        this.show = false
    }

    private async getProductByIds (cartItems: any[]) {
        if (!cartItems) return
        const populatedCartItems: any[] = []
        for (const item in cartItems) {
            try {
                const resp = await getStoreProductById(this.storeId, cartItems[item].product_id)
                populatedCartItems.push({
                    ...cartItems[item],
                    product: resp.data.data,
                })
            } catch (e) {
                console.log(e)
            }
        }
        this.cartItems = populatedCartItems
    }
}
